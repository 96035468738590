import _core from "@babel/core";
import _helperPluginUtils from "@babel/helper-plugin-utils";
import _util from "@mdx-js/util";
var exports = {};
const {
  types: t
} = _core;
const {
  declare
} = _helperPluginUtils;
const {
  startsWithCapitalLetter
} = _util;

class BabelPluginApplyMdxTypeProp {
  constructor() {
    const names = [];
    this.state = {
      names
    };
    this.plugin = declare(api => {
      api.assertVersion(7);
      return {
        visitor: {
          JSXOpeningElement(path) {
            const jsxName = path.node.name.name;

            if (startsWithCapitalLetter(jsxName)) {
              names.push(jsxName);
              path.node.attributes.push(t.jSXAttribute(t.jSXIdentifier(`mdxType`), t.stringLiteral(jsxName)));
            }
          }

        }
      };
    });
  }

}

exports = BabelPluginApplyMdxTypeProp;
export default exports;